import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { H } from "highlight.run";
import TagManager from "react-gtm-module";
import * as amplitude from "@amplitude/analytics-browser";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { ErrorBoundary } from "react-error-boundary";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./Common/AppRoutes";
import ErrorPage from "./Components/Feedback/ErrorPage";
import { HIGHLIGHT_PROJECT, REACT_APP_AMPLITUDE_API_KEY, REACT_APP_GTM_ID } from "./Common/Constants";
import { logError } from "./Common/Helper";

const NEW_RELIC_APP_ID_TEST = "1103289898";
const NEW_RELIC_APP_ID_PRODUCTION = "1103289911";
const environment = process.env.REACT_APP_ENVIRONMENT || "";

// H.init(HIGHLIGHT_PROJECT, {
//     serviceName: "Emovid-App",
//     tracingOrigins: true,
//     disableConsoleRecording: false,
//     reportConsoleErrors: true,
//     networkRecording: {
//         enabled: true,
//         recordHeadersAndBody: true
//     },
//     inlineImages: false
// });

// // configure newrelic
// if (environment && ["production", "test", "devops"].includes(environment)) {
//     const newRelicAppId = environment === "production" ? NEW_RELIC_APP_ID_PRODUCTION : NEW_RELIC_APP_ID_TEST;
//     let options = {
//         init: {
//             session_replay: {
//                 enabled: true,
//                 block_selector: "",
//                 mask_text_selector: "*",
//                 sampling_rate: 25.0,
//                 error_sampling_rate: 100.0,
//                 mask_all_inputs: true,
//                 collect_fonts: true,
//                 inline_images: false,
//                 inline_stylesheet: true,
//                 mask_input_options: {}
//             },
//             distributed_tracing: { enabled: true },
//             privacy: { cookies_enabled: true },
//             ajax: { deny_list: ["bam.nr-data.net"] }
//         },
//         loader_config: {
//             accountID: "4229002",
//             trustKey: "4229002",
//             agentID: newRelicAppId,
//             licenseKey: "NRBR-d75a342dce66a13f8c8",
//             applicationID: newRelicAppId
//         },
//         info: {
//             beacon: "bam.nr-data.net",
//             errorBeacon: "bam.nr-data.net",
//             licenseKey: "NRBR-d75a342dce66a13f8c8",
//             applicationID: newRelicAppId,
//             sa: 1
//         }
//     };
//     // The agent loader code executes immediately on instantiation.
//     new BrowserAgent(options);

//     // set highlight session
//     H.getSessionURL().then(url => window.newrelic.setCustomAttribute("highlightSessionUrl", url));
// }

let tagManagerArgs: { gtmId: string; dataLayer?: any } = {
    gtmId: REACT_APP_GTM_ID || ""
};

try {
    if (localStorage.getItem("userData")) {
        const userData = JSON.parse(localStorage.getItem("userData") || "");
        tagManagerArgs.dataLayer = {
            userId: userData.userId,
            userProject: `Emovid-${environment}`
        };
    }
} catch (error) {
    console.error("Error occurred during NR instantiation:", error);
}

TagManager.initialize(tagManagerArgs);
amplitude.init(REACT_APP_AMPLITUDE_API_KEY || "", {
    defaultTracking: true
});

try {
    if (environment && ["production", "test"].includes(environment)) {
        let perfEntries = performance.getEntriesByType("navigation");
        let lastSeen = new Date(0);
        if (localStorage.getItem("LastSeenTimestamp")) lastSeen = new Date(localStorage.getItem("LastSeenTimestamp") || 0);
        for (let i = 0; i < perfEntries.length; i++) {
            // @ts-ignore
            if (perfEntries[i].type === "navigate" && !window.location.search.includes("token") && window.location.pathname.includes("/record")) {
                let newSessionStartSinceLastSeen = (new Date().getTime() - lastSeen.getTime()) / 1000;
                if (newSessionStartSinceLastSeen < 15)
                    logError(`Possible crash Detected`, new Error("Possible crash Detected", { cause: perfEntries[i] }), {
                        referrer: window.location.href,
                        highlight_session: localStorage.getItem("last_highlight_session")
                    }); // Possible crash
            }
        }
        setInterval(() => localStorage.setItem("LastSeenTimestamp", `${new Date()}`), 1000);
    }
} catch (error) {
    console.log(error);
}

const logErrorStackTrace = (error: Error, info: React.ErrorInfo) => {
    console.error(error, info.componentStack);
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
if (["production", "test", "use"].includes(environment))
    root.render(
        <ErrorBoundary FallbackComponent={ErrorPage} onError={logErrorStackTrace}>
            <AppRoutes />
        </ErrorBoundary>
    );
else
    root.render(
        <React.StrictMode>
            <ErrorBoundary FallbackComponent={ErrorPage} onError={logErrorStackTrace}>
                <AppRoutes />
            </ErrorBoundary>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
